import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";
import logo from "../images/logo.png";

function Header() {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="mx-4">
      <div className="flex flex-col mt-16 max-w-2xl mx-auto">
        <Link to="/" className="sm:mx-auto ml-2">
          <img src={logo} alt="Logo" className="w-8 h-8" />
        </Link>
        <nav className="mt-6 flex space-y-2 sm:space-y-0 sm:space-x-8 flex-col sm:flex-row text-gray-400 font-medium text-lg">
          {[
            {
              route: `/`,
              title: site.siteMetadata.title,
            },
            {
              route: `/writing`,
              title: `Writing`,
            },
            // {
            //   route: `/projects`,
            //   title: `Projects`,
            // },
            // {
            //   route: `/about`,
            //   title: `About`,
            // },
          ].map((link) => (
            <div className="inline-block" key={link.title}>
              <Link
                activeClassName="text-blue-500"
                className="px-2 py-1 rounded-lg hover:bg-yellow-200 hover:text-blue-500"
                to={link.route}
              >
                {link.title}
              </Link>
            </div>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
